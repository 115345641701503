// Home.tsx
import React from "react";
import "./Home.scss";
import profileImage from "../Assets/new-home.svg";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  return (
    <div className="home-body">
      <div className="profile-image-container">
        <img
          src={profileImage}
          alt="Cailee Adamson"
          className="profile-image"
        />
      </div>
      <div className="right-container">
        <div className="box left-top">
          <Link to="/resume">Work Resume</Link>
        </div>
        <div className="box right-top">
          <Link to="/about">About Me</Link>
        </div>
        <div className="box left-bottom">
          <Link to="/art">Art</Link>
        </div>
        <div className="box right-bottom">
          <Link to="/music">Music</Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
