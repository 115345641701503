import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import AboutMe from "./Pages/AboutMe";
import Resume from "./Pages/Resume";
import Art from "./Pages/Art";
import Music from "./Pages/Music";

const SiteRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutMe />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/art" element={<Art />} />
      <Route path="/music" element={<Music />} />
    </Routes>
  );
};

export default SiteRoutes;
