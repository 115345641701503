import React from "react";
import "./Resume.scss";

const Resume: React.FC = () => {
  const skills = [
    "JavaScript",
    "React/Redux",
    "TypeScript",
    "Ruby on Rails",
    "HTML/CSS",
    "Git",
    "Figma",
    "Jira",
    "Cypress",
    "Cucumber",
    "Selenium",
    "Browserstack",
    "Percy",
    "Github Actions",
    "CircleCI",
    "Datadog",
    "Sentry",
    "AWS",
  ];

  const education = [
    "Hunter College, 2013-2014",
    "University of Vermont, Art History, 2011-2013",
  ];

  return (
    <div className="resume-container">
      <div className="resume-content">
        {/* Left Column - Resume Details */}
        <div className="left-column">
          <h1>WORK EXPERIENCE</h1>

          {/* Job 1 */}
          <div className="job-entry">
            <h3>Software Engineer @ Teachable</h3>
            <p className="year">December 2022 - Present</p>
            <ul>
              <li>
                Working with technologies such as Typescript, React, and Ruby on
                Rails
              </li>
            </ul>
          </div>

          {/* Job 2 */}
          <div className="job-entry">
            <h3>QA Engineer @ Teachable</h3>
            <p className="year">March 2021 - December 2022</p>
            <ul>
              <li>
                Performed manual and automated testing across mulitple pods
              </li>
              <li>
                Trained and onboarded QA engineers and software developers on
                best quality practices, testing tools, and testing methods
              </li>
              <li>
                Tested and helped launch a new internal product that generated
                $1 million in GMV within the first quarter
              </li>
              <li>
                Led cross-functional testing activities for large company
                initiatives
              </li>
            </ul>
          </div>

          {/* Job 3 */}
          <div className="job-entry">
            <h3>QA Engineer @ Feather</h3>
            <p className="year">September 2019 - February 2021</p>
            <ul>
              <li>
                Defined and implemented a manual and autmated testing strategy
              </li>
              <li>
                Coordinated testing activities cross-functionally with multiple
                teams
              </li>
              <li>Hired and managed 1 QA engineer</li>
            </ul>
          </div>

          {/* Job 4 */}
          <div className="job-entry">
            <h3>Product Manager @ Flatiron School</h3>
            <p className="year">January 2019 - August 2019</p>
            <ul>
              <li>
                Identified business problems, created product specs, and
                coordinated with engineering and design to launch internal and
                external features
              </li>
            </ul>
          </div>

          {/* Job 5 */}
          <div className="job-entry">
            <h3>Quality Assurance @ Flatiron School</h3>
            <p className="year">June 2018 - January 2019</p>
            <ul>
              <li>
                Created a software testing process to serve a scaling team
              </li>
            </ul>
          </div>

          {/* Job 6 */}
          <div className="job-entry">
            <h3>Student Support @ Flatiron School</h3>
            <p className="year">February 2017 - June 2018</p>
            <ul>
              <li>
                Responsible for a providing product support for a high volume of
                customers
              </li>
              <li>Hired and managed a team of product support specialists</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Right Column - Image */}
      <div className="resume-secondary">
        <h1>SKILLS</h1>
        <ul>
          {skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
        <h1>EDUCATION</h1>
        <ul>
          {education.map((school, index) => (
            <li key={index}>{school}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Resume;
