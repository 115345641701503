import React from "react";
import "./ComingSoon.scss";

const Art: React.FC = () => {
  return (
    <div className="coming-soon-body">
      <div className="coming-soon-container">
        <h2>COMING SOON</h2>
        <p>caileeadamson@gmail.com</p>
      </div>
    </div>
  );
};

export default Art;
