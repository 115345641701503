import React from "react";
import { useLocation } from "react-router-dom";
import Routes from "./Routes";
import "./App.scss";
import Navigation from "./Navigation/Navigation";

const App: React.FC = () => {
  const location = useLocation();
  const hideNavbar = location.pathname === "/";

  return (
    <div className="app-body">
      {!hideNavbar && <Navigation />}
      <Routes />
    </div>
  );
};

export default App;
