// Navbar.tsx
import React from "react";
import { Link } from "react-router-dom";
import "./DesktopNav.scss";
import logo from "../Assets/new-logo.svg";

const DesktopNav: React.FC = () => {
  return (
    <nav className="desktopnav">
      <Link to="/">
        <img src={logo} alt="Your Name" className="logo" />
      </Link>
      <div className="menu-items">
        <Link to="/resume" className="navbar-link">
          Resume
        </Link>
        <Link to="/about" className="navbar-link">
          About
        </Link>
        <Link to="/art" className="navbar-link">
          Art
        </Link>
        <Link to="/music" className="navbar-link">
          Music
        </Link>
      </div>
    </nav>
  );
};

export default DesktopNav;
