// AboutMe.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faInstagram,
  faSpotify,
} from "@fortawesome/free-brands-svg-icons";
// import Navbar from "./Navbar";
import profileImage from "../Assets/flower.svg";
import "./AboutMe.scss";

const AboutMe: React.FC = () => {
  return (
    <div className="about-me-container">
      <div className="text-container">
        <h2>About Me</h2>
        <p>
          I have a background in art history and now work as a software
          engineer. I grew up in upstate New York and briefly attended school in
          Vermont before settling in NYC. My hobby is having hobbies and I enjoy
          working with my hands making things from stone sculptures to new
          countertops for my kitchen.
        </p>
      </div>
      <div className="profile-container">
        <div className="profile-image-container">
          <img src={profileImage} alt="Your Name" className="profile-image" />
        </div>
        <div className="social-icons">
          <a
            href="https://www.linkedin.com/in/cailee/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href="https://www.instagram.com/cailpail/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://open.spotify.com/artist/5tYwPecZ6DfFj7Hz1R9sLB?si=5eqUrRThQKOR678niZnOsw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faSpotify} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
